<template>
    <div class="flex justify-center">
        <div class="sistems mw-1920">
            
            <div class="sistem-item btn-4" data-aos="fade-up"  data-aos-offset="80" data-aos-easing="ease-out-cubic"  >
                <div class="system-content">
                <div class="img-div"> 
                    <img :src="Pages.files.blog2Image1 ? `https://avior.prestigeautofl.com${Pages.files.blog2Image1}` : require(`@/assets/icons/gift.svg`)" alt="">
                </div>
                <p> {{Pages.data.blog2[`title1_${$i18n.locale}`]}} </p>
                </div>
            </div> 
            
            <div class="sistem-item btn-4" data-aos="fade-up" data-aos-offset="80" data-aos-easing="ease-out-cubic"  data-aos-delay="200" >
                <div class="system-content">
                <div class="img-div">
                    <img :src="Pages.files.blog2Image2  ? `https://avior.prestigeautofl.com${Pages.files.blog2Image2}` : require(`@/assets/icons/starphone.svg`)" alt="">
                </div>
                <p>{{Pages.data.blog2[`title2_${$i18n.locale}`]}}</p>
                </div>
            </div> 
            
            <div class="sistem-item btn-4" data-aos="fade-up" data-aos-offset="80" data-aos-easing="ease-out-cubic" data-aos-delay="400">
                <div class="system-content">
                <div class="img-div"> 
                    <img :src="Pages.files.blog2Image3  ? `https://avior.prestigeautofl.com${Pages.files.blog2Image3}` : require(`@/assets/icons/withrestert.svg`)" alt="">
                </div>
                <p>{{Pages.data.blog2[`title3_${$i18n.locale}`]}}</p>
                </div>
            </div> 
            
            <div class="sistem-item btn-4" data-aos="fade-up" data-aos-offset="80" data-aos-easing="ease-out-cubic"  data-aos-delay="600">
                <div class="system-content">
                <div class="img-div"> 
                    <img :src="Pages.files.blog2Image4  ? `https://avior.prestigeautofl.com${Pages.files.blog2Image4}` : require(`@/assets/icons/doamoncard.svg`)" alt="">
                </div>
                <p>{{Pages.data.blog2[`title4_${$i18n.locale}`]}}</p>
                </div>
            </div> 
        </div>
    </div>
</template>
<script> 

export default {
    computed:{
        Pages() {
            return this.$store.state.Home
        }
    }
}
</script>

<style lang="scss">
.sistems {
    padding: 150px 120px; 
    display: flex;
    justify-content: center;

    .sistem-item {
        margin-top: 20px;
        margin-right: 140px;
        max-width: 200px; 

        p {
            font-family: Stem-Medium;
            font-size: 1.125rem;
            line-height: 1.25rem;
            text-align: center;
            color: var(--white-black);

        }

        .img-div {
            width: 100%;
            height: 110px;
            display: flex;
            justify-content: center;
            padding: 20px;

        }
    }

    .sistem-item:last-child {
        margin-right: 0px;
    }

    .sistem-item:hover {
        p {
            color: var(--color-blue);
        }

        ;

    }
}

@media screen and(max-width:1100px) {
    .sistems {
        padding: 100px 20px;

        .sistem-item {
            margin-right: 40px;
        }
    }
}

@media screen and(max-width:668px) {
    .sistems {
        padding: 50px 20px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .sistem-item {
            margin-top: 60px;
            margin-right: 0px;
            width: 100%;
            max-width: 100%;
            cursor: pointer;
            display: flex;
            align-items: center;
            .system-content{
                display: flex;
                align-items: center;
            }

            p {
                font-size: 0.875rem;
                line-height: 1rem;
                text-align: left;
                padding-left: 10px;

            }

            .img-div {
                min-width: 130px;
                width: 130px;
                height: 90px;
                display: flex;
                justify-content: flex-start;
                padding: 10px 20px 10px 10px;

            }
        }
    }

}

</style>