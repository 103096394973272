<template>
<div class="home">
    <div class="flex justify-center">
        <div class="home-top w-full mw-1920 pt-200">
            <div class="home-top-items" v-if="!loading">
                <h1 data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-easing="ease-in-sine">{{ Pages.data.blog1[`title_${$i18n.locale}`] ? Pages.data.blog1[`title_${$i18n.locale}`] :''}}</h1>
                <p data-aos="fade-up" data-aos-offset="0"> {{Pages.data.blog1[`description_${$i18n.locale}`] ? Pages.data.blog1[`description_${$i18n.locale}`]:''}}</p>
                <div class="home-top-buttons">
                    <button class="button blue mt-20" @click="$router.push('/credit')"> <span>{{Pages.data.blog1[`button_${$i18n.locale}`]}}</span> </button>
                </div>

            </div>
            <div class="home-top-items" v-if="loading">
                <el-skeleton v-if="loading" animated />
            </div>

            <div class="top-images" v-if="Pages.files.blog1Image1">
                <div class="transformer opacity-anime">
                    <!-- <img :src="`https://avior.prestigeautofl.com${Pages.files.blog1Image}`"   alt=""> -->
                    <img :src="`https://avior.prestigeautofl.com${Pages.files.blog1Image1}`" class=" z-10 big-bg1 pr-img" data-aos="opacity-anime2" alt="">
                    <img :src="`https://avior.prestigeautofl.com${Pages.files.blog1Image2}`" class=" z-10  big-bg2 pr-img" data-aos="opacity-anime1" alt="">
                    <img :src="`https://avior.prestigeautofl.com${Pages.files.blog1Image3}`" class=" z-10  big-bg3 pr-img" data-aos="opacity-anime-car" alt="">
                    <div class="home-top-clouds">
                        <img :src="`https://avior.prestigeautofl.com${Pages.files.blog1Cloud}`" class="big-bg-clouds1  " alt="">
                        <img :src="`https://avior.prestigeautofl.com${Pages.files.blog1Cloud}`" class="big-bg-clouds2  " alt="">
                        <img :src="`https://avior.prestigeautofl.com${Pages.files.blog1Cloud}`" class="big-bg-clouds3  " alt="">
                        <img :src="`https://avior.prestigeautofl.com${Pages.files.blog1Cloud}`" class="big-bg-clouds4  " alt="">
                    </div>
                </div>
            </div>
            <!-- <div class="top-images"  v-else> 
                    <img src="@/assets/img/big-bg2-bg.svg" class="big-bg-bg desktop" data-aos="opacity-anime1" alt="">
                    <img src="@/assets/img/big-bg1.svg" class="desktop z-10 big-bg1 pr-img" data-aos="opacity-anime2" alt="">
                    <img src="@/assets/img/big-bg2.svg" class="desktop z-10  big-bg2 pr-img" data-aos="opacity-anime1"  alt="">
                    <img src="@/assets/img/big-bg3.svg" class="desktop z-10  big-bg3 pr-img" data-aos="opacity-anime-car"  alt="">
                    <img src="@/assets/img/img-topphone.svg" class="phone550 pr-img" alt="">
                    <img src="@/assets/img/top-img768.svg" class="phone768 pr-img" alt="">

                    <div class="home-top-clouds">
                        <img src="@/assets/img/home-top-clouds.svg" class="big-bg-clouds1  " alt="">
                        <img src="@/assets/img/home-top-clouds.svg" class="big-bg-clouds2  " alt="">
                        <img src="@/assets/img/home-top-clouds.svg" class="big-bg-clouds3  " alt="">
                        <img src="@/assets/img/home-top-clouds.svg" class="big-bg-clouds4  " alt="">
                    </div>
            </div> -->

        </div>
    </div>

    <SystemLinks />
    <Credit show="true" :buttonText="Pages.data.blog3[`button_${$i18n.locale}`]" :title="Pages.data.blog3[`title_${$i18n.locale}`]" />
    <div class="flex justify-center">
        <div class="stock mw-1920">
            <div class="stock-title">
                <h1 data-aos="fade-up">{{ Pages.data.blog4[`title_${$i18n.locale}`]}}</h1>
                <p data-aos="fade-up">{{ Pages.data.blog4[`description_${$i18n.locale}`]}}</p>
            </div>
        </div>
    </div>
    <div class="flex justify-center">
        <div class="mw-1920 w-full flex justify-center">
            <div class="stock mw-1440">
                <div class="stock-list">
                    <StockCard v-for="(item, i) in StocksList.data" :key="i" :data="item" />
                    <div class="w-full flex justify-center mt-40px">
                        <a :href="Pages.data.blog4.url">
                            <button class="button">{{Pages.data.blog4[`button_${$i18n.locale}`]}}</button>
                        </a>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="flex justify-center" data-aos="fade-up">
        <div class="leasing mw-1920">
            <div class="leasing-left">
                <div class="leasing-title">
                    <h1 data-aos="fade-up"> {{ Pages.data.blog5[`title_${$i18n.locale}`]}} </h1>
                    <p data-aos="fade-up">{{ Pages.data.blog5[`description_${$i18n.locale}`]}}</p>
                </div>
                <div class="leasing-item">
                    <p data-aos="fade-up">{{ Pages.data.blog5[`text_${$i18n.locale}`]}}</p>
                    <div class="flex items-center leasing-buttons">
                        <a  :href="Pages.data.blog5.button1_url" class="button " data-aos="fade-up">{{Pages.data.blog5[`button1_${$i18n.locale}`]}}</a>
                        <button class="button blue" @click="modal=true" data-aos="fade-up" data-aos-delay="200">{{Pages.data.blog5[`button2_${$i18n.locale}`]}}</button>
                    </div>
                </div>
            </div>
            <div class="leasing-right" v-if="Pages.files.blog5Image">
                <!-- <div  class="leasing-dinamic"  data-aos="opacity-anime1"> 
                    <img :src="Pages.files.blog5Image? `https://avior.prestigeautofl.com${Pages.files.blog5Image}`: require(`@/assets/icons/leasingdown.svg`)" alt="">
                </div> -->

                <div class="leasing-anime1 " data-aos="opacity-anime1">
                    <img :src="`https://avior.prestigeautofl.com${Pages.files.blog5Image1}`" class="img" alt="">
                </div>
                <div class="leasing-anime2" data-aos="opacity-anime3">
                    <img :src="`https://avior.prestigeautofl.com${Pages.files.blog5Image2}`" class="img" alt="">
                </div>
                <div class="leasing-anime3" data-aos="opacity-anime2">
                    <img :src="`https://avior.prestigeautofl.com${Pages.files.blog5Image3}`" class="img" alt="">
                </div>
                <div class="leasing-clouds" data-aos="opacity-anime1">
                    <img :src="`https://avior.prestigeautofl.com${Pages.files.blog5Cloud}`" class=" lasingAnime" alt="">
                    <img :src="`https://avior.prestigeautofl.com${Pages.files.blog5Cloud}`" class=" lasingAnime delay-1" alt="">
                </div>

            </div>
            <!-- <div class="leasing-right" v-else>
                <div class="leasing-anime1 desktop" data-aos="opacity-anime1">
                    <img src="@/assets/img/leasinganime1.svg" class="img" alt="" >
                </div>
                <div class="leasing-anime2 desktop" data-aos="opacity-anime2"   >
                    <img src="@/assets/img/leasinganime2.svg" class="img" alt="">
                </div>
                <div class="leasing-anime3 desktop" data-aos="opacity-anime3" >
                    <img src="@/assets/img/leasinganime3.svg" class="img" alt="">
                </div>
                <img src="@/assets/img/leasing.svg" class="phone" alt="AAA"> 
                <div class="leasing-clouds" data-aos="opacity-anime1">
                    <img   src="@/assets/img/lizingClouds.svg" class="desktop lasingAnime" alt="">
                    <img   src="@/assets/img/lizingClouds.svg" class="desktop lasingAnime delay-1" alt=""> 
                </div>
            </div> -->
        </div>
    </div>
    <div class="flex justify-center" id="prevagi">
        <div class="benefits mw-1920 w-full">
            <div class="benefits-title">
                <h1 data-aos="fade-up"> {{ Pages.data.blog6[`title_${$i18n.locale}`]}} </h1>
                <p data-aos="fade-up"> {{ Pages.data.blog6[`description_${$i18n.locale}`]}} </p>
            </div>
            <div class="benefit-items">
                <div class="benefit-item" data-aos="fade-up">
                    <div class="benefit-card">
                        <a :href="Pages.data.blog6.url1">
                            <div class="benefit-card-img">
                                <img :src="Pages.files.blog6Image1? `https://avior.prestigeautofl.com${Pages.files.blog6Image1}`: require(`@/assets/icons/handclock.svg`)" alt="">
                            </div>
                            <div class="benefit-card-text">
                                <p>{{ Pages.data.blog6[`title1_${$i18n.locale}`]}}</p>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="benefit-item" data-aos="fade-up" data-aos-delay="200">
                    <div class="benefit-card">
                        <a :href="Pages.data.blog6.url2">
                            <div class="benefit-card-img">
                                <img :src="Pages.files.blog6Image2? `https://avior.prestigeautofl.com${Pages.files.blog6Image2}`: require(`@/assets/icons/leasinghuman.svg`)" alt="">
                            </div>
                            <div class="benefit-card-text">
                                <p>{{ Pages.data.blog6[`title2_${$i18n.locale}`]}}</p>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="benefit-item" data-aos="fade-up" data-aos-delay="400">
                    <div class="benefit-card">
                        <a :href="Pages.data.blog6.url3">
                            <div class="benefit-card-img">
                                <img :src="Pages.files.blog6Image3? `https://avior.prestigeautofl.com${Pages.files.blog6Image3}`: require(`@/assets/icons/leasingclock.svg`)" alt="">
                            </div>
                            <div class="benefit-card-text">
                                <p>{{ Pages.data.blog6[`title3_${$i18n.locale}`]}}</p>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="benefit-item" data-aos="fade-up" data-aos-delay="600">
                    <div class="benefit-card">
                        <a :href="Pages.data.blog6.url4">
                            <div class="benefit-card-img">
                                <img :src="Pages.files.blog6Image4? `https://avior.prestigeautofl.com${Pages.files.blog6Image4}`: require(`@/assets/icons/leasingkredit.svg`)" alt="">
                            </div>
                            <div class="benefit-card-text">
                                <p>{{ Pages.data.blog6[`title4_${$i18n.locale}`]}}</p>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="benefit-item" data-aos="fade-up" data-aos-delay="800">
                    <div class="benefit-card">
                        <a :href="Pages.data.blog6.url5">
                            <div class="benefit-card-img">
                                <img :src="Pages.files.blog6Image5? `https://avior.prestigeautofl.com${Pages.files.blog6Image5}`: require(`@/assets/icons/leasingdown.svg`)" alt="">
                            </div>
                            <div class="benefit-card-text">
                                <p>{{ Pages.data.blog6[`title5_${$i18n.locale}`]}}</p>
                            </div>
                        </a>
                    </div>
                </div>

            </div>
            <div class="benefit-button">
                <a :href="Pages.data.blog6.button_url">
                    <button class="button" data-aos="fade-up">{{Pages.data.blog6[`button_${$i18n.locale}`]}}</button>
                </a>
            </div>
        </div>
    </div>
    <div class="flex justify-center bg-gray-light" data-aos="fade-up">
        <div class="about-us mw-1920 ">
            <div class="about-us-items">
                <div class="about-us-item">
                    <img :src="Pages.files.blog7Image? `https://avior.prestigeautofl.com${Pages.files.blog7Image}`: require(`@/assets/img/big-a.svg`)" alt="">
                </div>
                <div class="about-us-item">
                    <h1 data-aos="fade-up"> {{ Pages.data.blog7[`title_${$i18n.locale}`]}} </h1>
                    <p data-aos="fade-up"> {{ Pages.data.blog7[`description_${$i18n.locale}`]}} </p>
                    <a :href="Pages.data.blog7.button_url"><button class="button" data-aos="fade-up">{{Pages.data.blog7[`button_${$i18n.locale}`]}}</button></a>
                </div>
            </div>
        </div>
    </div>
    <div class="modal" :class="modal?'active':''">
        <div @click="modal=false" class="modal-cover"></div>
        <div class="contact-card" v-loading="smsLoading">
            <el-form ref="smsForm" :model="sms" :rules="smsRules">
                <h1> {{$t('coming_soon')}} </h1>
                <el-form-item prop="name">
                    <el-input :placeholder="$t('enter_mail')" class="contact-card-input" v-model="sms.name"></el-input>
                </el-form-item>
                <el-form-item prop="text">
                    <el-input type="textarea" :rows="5" class="contact-card-text-area" :placeholder="$t('enter_text')" v-model="sms.text"> </el-input>
                </el-form-item>
                <div class="flex justify-center">
                    <button class="button" type="button" @click="SendSms"> {{$t('contact_send')}} </button>
                </div>
            </el-form>
        </div>
    </div>
</div>
</template>

<script>
//  import RangeSlider from "../components/sliderrange.vue"
import SystemLinks from '../components/SystemLinks.vue'
import Credit from '../components/Credit.vue'
import StockCard from '../components/StockCard.vue'
export default {
    name: 'Home',
    components: {
        SystemLinks,
        Credit,
        StockCard
    },
    data() {
        return {
            a: 0,
            modal: false,
            scrollPos: 0,
            allowNoActive: false,
            kredit_type: 'pledge',
            anime1: false,
            windowTop: window.top.scrollY,
            StocksList: [{}],
            home: {},
            stock: {},
            leasing: {},
            benefit: {},
            about: {},
            loading: false,
            sms: {
                name: '',
                text: ''
            },
            smsLoading: false,
            smsRules: {
                name: [{
                     required: true,
                     type:'email',
                     message: 'Введіть вашу електронну пошт!',
                     trigger: 'change'
                }],
                text: [{
                    required: true,
                    message: this.$t('input_text_please'),
                    trigger: 'change'
                }],
            }
        }
    },
    computed: {
        Pages() {
            return this.$store.state.Home
        },
        me() {
            return this.$store.state.me
        },
    },
    methods: {
        GetStocks() {
            try {
                this.axios.get('/stock?per_page=6').then(res => {
                    this.StocksList = res.data.data
                })
            } catch {
                console.log("0")
            }

        },
        SendSms() {

             this.$refs.smsForm.validate((valid) => {
                 if (valid) {
                     this.$notify({
                         title: 'Успех',
                         message: 'Ваш запрос был успешно отправлен!',
                         type: 'success'
                     });
                     this.smsLoading = false
                     this.$refs.smsForm.resetFields();
                     this.modal=false
                 } else {
                     return false;
                 }
             });
         }
    },
    mounted() {
        this.GetStocks()
    }

}
</script>

<style lang="scss">
.big-bg1 {
    position: absolute;
    z-index: 2;
}

.big-bg2 {
    position: relative;
    z-index: 1;
}

.big-bg3 {
    position: absolute;
    z-index: 1;
}



.about-us {
    background: var(--white-gray-bg);
    width: 100%;

    .about-us-items {
        padding: 0px 80px;
        display: flex;

        .about-us-item {
            width: 50%;
            padding: 115px 40px;
            position: relative;

            h1 {
                font-family: Stem-Medium;
                font-size: 40px;
                line-height: 42px;
                font-feature-settings: 'tnum'on, 'lnum'on;
                color: var(--other-black);
            }

            p {
                font-family: Stem-Regular;
                font-size: 1.125rem;
                line-height: 24px;
                font-feature-settings: 'tnum'on, 'lnum'on;
                color: var(--white-black);
                margin: 20px 0px;
            }

            button {
                margin-top: 40px;
            }

            img {
                position: absolute;
                left: 0;
                bottom: 0;
            }
        }
    }
}

@media screen and(max-width:1100px) {
    .about-us {
        background: var(--bg-top);

        .about-us-items {
            padding: 0px 20px;
        }
    }
}

@media screen and(max-width:950px) {
    .about-us {
        background: var(--bg-top);

        .about-us-items {
            padding: 0px 20px;
            display: flex;
            flex-direction: column-reverse;

            .about-us-item {
                width: 100%;
                padding: 70px 20px 0px 20px;

                img {
                    position: relative;
                    left: 0;
                    bottom: 0;
                    width: 50%;
                    margin: 0 auto;
                }
            }
        }
    }
}

@media screen and(max-width:768px) {
    .about-us {
        background: var(--bg-top);

        .about-us-items {
            padding: 0px 20px;
            display: flex;
            flex-direction: column-reverse;

            .about-us-item {
                width: 100%;
                padding: 70px 20px 0px 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;

                h1 {
                    font-size: 24px;
                    line-height: 28px;
                    text-align: center;
                }

                p {
                    font-size: 0.875rem;
                    line-height: 24px;
                    text-align: center;
                }

                button {
                    margin-top: 40px;
                }

                img {
                    position: relative;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                }
            }
        }
    }
}

@media screen and(min-width:1700px) {
    .about-us {
        .about-us-items {
            padding: 0px 120px;
        }
    }
}





@media screen and(max-width:768px) {
    .contact-card {
        padding: 25px;
        max-width: 570px;
        position: relative;
        z-index: 1;

        h1 {
            font-size: 1rem;
            line-height: 1.125rem;
        }

        .contact-card-input {
            .el-input__inner {
                padding: 15px 20px;
                margin-top: 25px;
                font-size: 1rem;
                line-height: 1.125rem;
            }
        }

        .contact-card-text-area {
            margin-top: 20px;
            width: 100%;

            .el-textarea__inner {
                font-size: 1rem;
                line-height: 1.125rem;
                padding: 15px 20px;
            }
        }
    }
}
</style>
