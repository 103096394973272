<template>
<div class="stock-card-item" data-aos="fade-up" data-aos-easing="ease-in-sine">
    <div class="stock-phone-bg" data-aos="zoom-in" data-aos-easing="ease-in-sine" :style="{'background':`url(https://avior.prestigeautofl.com/${data.image})`}">
        <div class="stock-phone-bg-cover"></div>
    </div>
    <div class="stock-card" :class="active?'active':''" >
        <div class="stock-card-bg" :style="{'background':`url(https://avior.prestigeautofl.com/${data.image})`}"> 
        </div>
        <div class="stock-card-text">
            <h1> {{data[`title_${$i18n.locale}`]}} </h1>
            <p> {{data[`title_${$i18n.locale}`]}} </p>
        </div>
        <div class="stock-card-bottom">
            <button @click="$router.push(`/stocks/${data.id}`)" @mouseenter="active=true" @mouseout="active=false">{{$t('stock_show')}}</button>
            <p>{{data.start_date.split('-')[2] }}.{{data.start_date.split('-')[1]}} - {{data.end_date.split('-')[2]}}.{{data.end_date.split('-')[1]}}.{{data.end_date.split('-')[0]}}</p>
        </div>
    </div>
</div>
</template>
<script>
 

export default {
    props:['data'],
    data() {
        return{
            active:false
        }
    },
}
</script>

