<template>
    <div class="flex justify-center w-full"   >
        <div class="credit-count mw-1920 w-full">
            <h1 data-aos="fade-up" v-if="title">{{ title? title: $t('calculator_title')}}</h1>
            <div class="credit-count-items" data-aos="fade-up" >
                <div class="header-cover">
                    <div class="credit-header">
                        <div class="credit-header-left "   :class="kredit_type=='pledge'?'credit-header-active':''" @click="kredit_type='pledge'">
                            {{credit.data[`type1_${$i18n.locale}`]}}
                        </div>
                        <div class="credit-header-right"   :class="kredit_type=='collateral'?'credit-header-active':''" @click="kredit_type='collateral'">
                            {{credit.data[`type2_${$i18n.locale}`]}}
                        </div>
                    </div>
                </div>
                <div class="credit-item">
                    <div class="credit-item-left">
                        <div class="credit-slider" data-aos="fade-up">
                            <div class="credit-slider-top-text">
                                <h3> {{credit.data[`count_${$i18n.locale}`]}}</h3> 
                                <div class="credit-input">
                                    <input type="number" class="input" 
                                    onkeypress="this.style.width = (this.value.length) + 'em';" 
                                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" 
                                    :minlength="credit.data.min_sum.length" 
                                    :maxlength="credit.data.max_sum.length" 
                                    @keypress="Valid($event)"
                                    :class="disabled?'erorr':''"
                                    v-model="creditCount.count" />
                                     <p :class="disabled?'erorr':''"> ₴</p>
                                </div>
                                
                            </div>
                            <RangeSlider :max="credit.data.max_sum" :setp="0.1*credit.data.min_sum" :min="credit.data.min_sum" v-model="creditCount.count" />
                            <div class="credit-slider-bottom-text">
                                <p>{{credit.data.min_sum}} ₴</p>
                                <p>{{credit.data.max_sum}} ₴</p>
                            </div>
                        </div>
                        <div class="credit-slider" data-aos="fade-up">
                            <div class="credit-slider-top-text">
                                <h3> {{credit.data[`term_${$i18n.locale}`]}} </h3>
                                <p>{{creditCount.month}} {{credit.data[`month_${$i18n.locale}`]}}</p>
                            </div>
                            <RangeSlider :max="credit.data.max_month" :min="credit.data.min_month" :setp="1" v-model="creditCount.month" />
                            <div class="credit-slider-bottom-text">
                                <p> {{credit.data.min_month}}  {{credit.data[`month_${$i18n.locale}`]}}</p>
                                <p>{{credit.data.max_month}} {{credit.data[`month_${$i18n.locale}`]}}</p>
                            </div>
                        </div>

                    </div>
                    <div class="credit-item-right">
                        <div class="credit-right-card">
                            <div class="credit-right-card-header">
                                <p>{{credit.data[`count_${$i18n.locale}`]}}</p>
                                <p>{{creditCount.count}} ₴</p>

                            </div>
                            <div class="credit-right-card-body">
                                <div class="body_item">
                                    <p>{{credit.data[`accrual_${$i18n.locale}`]}}</p>
                                    <p> {{creditCount.accrual}} ₴</p>
                                </div>
                                <div class="body_item">
                                    <p>{{credit.data[`redemption_${$i18n.locale}`]}}</p>
                                    <p>{{creditCount.redemption}} ₴</p>
                                </div>
                                <div class="body_item">
                                    <p>{{credit.data[`turning_${$i18n.locale}`]}}</p>
                                    <p>{{creditCount.lastDate}}</p>
                                </div>
                                <div class="body_item">
                                    <p>{{credit.data[`percent_${$i18n.locale}`]}}</p>
                                    <p>{{creditCount.percenti}} %</p>
                                </div>
                                <div class="body_item_button">
                                    <button class="button"  :disabled="disabled" @click="Check"   :class="disabled?'disabled_button':''">{{credit.data[`button_${$i18n.locale}`]}} </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div> 
        </div>
        
    </div>
</template>
<script> 
import RangeSlider from './sliderrange.vue'
export default {
    props:['show', "title", "buttonText"],
    components:{RangeSlider},
    computed:{
        credit(){
            return this.$store.state.Calculator
        },
        me(){
            return this.$store.state.me
        }
    },
    data() {
        return{
            a:1,
            kredit_type:'pledge',
            credit_percent_year:10,
            credit_percent_month:10,
            creditCount:{
                count:  1000,
                accrual:0,
                redemption:0,
                month:1,
                lastDate:'--.--.--',
                percenti:10

            },
            disabled:false
        }
    },
    methods:{
        countDate(month){
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            mm=parseInt(mm)
            yyyy=parseInt(yyyy)
            var yyyy = today.getFullYear();
            var lastyear=parseInt(month/12)
            var lastmonth=month-lastyear*12 
            yyyy=yyyy+parseInt((mm+lastmonth)/12)+lastyear
            mm=(mm+lastmonth)%12
            mm=mm==0? '01': mm<10? '0'+mm : mm
            this.creditCount.lastDate = dd  + '.' +mm+ '.' +yyyy;

        },
        Valid(event){ 
             if( parseFloat(this.creditCount.count)> parseFloat(this.credit.data.max_sum)){
                 this.creditCount.count=this.credit.data.max_sum
             }
             let keyCode = (event.keyCode ? event.keyCode : event.which);
            if ((keyCode < 48 || keyCode > 57)) { // 46 is dot
                event.preventDefault();
            }
         },
        countCredit(cost, month){
            
            if(month>11){ this.creditCount.percenti= parseInt(this.credit_percent_year) }
            else{ this.creditCount.percenti= parseInt(this.credit_percent_month)  }
            var CostAccrual=0
            
             
            for(let i=1; i<=month; i++){
                CostAccrual=parseInt(CostAccrual) +parseInt( (parseInt(cost)*parseInt(this.creditCount.percenti) )/100)
            }
            
            this.creditCount.redemption= parseInt(cost)+parseInt(CostAccrual)
            this.creditCount.accrual=CostAccrual

        },
          Check(){ 
            this.$router.push(`${this.credit.data.url}?amount=${this.creditCount.count}&month=${this.creditCount.month}`)
        }
         
    },
    watch:{
        'creditCount.month'(val){
            this.countCredit(this.creditCount.count, val)
            this.countDate(val)
        },
        'creditCount.count'(val){
            this.countCredit(val, this.creditCount.month)
            this.disabled=false
            let Num = /^[0-9]+$/
            if (!Num.test(val) || parseFloat(this.creditCount.count)<parseFloat(this.credit.data.min_sum)) {
                this.disabled=true
            } 
             
        },
        credit(){
            this.creditCount.month=this.credit&&this.credit.data&&this.credit.data.min_month ? this.credit.data.min_month :1
            this.creditCount.count= this.credit.data && this.credit.data.min_sum? this.credit.data.min_sum :1000
            this.credit_percent_year=this.credit&&this.credit.data&&this.credit.data.percent_year ? this.credit.data.percent_year :10
            this.credit_percent_month=this.credit&&this.credit.data&&this.credit.data.percent_month ? this.credit.data.percent_month :10
        }
    },
    mounted(){
        this.countCredit(this.creditCount.count, this.creditCount.month)
        this.countDate(1)

        this.creditCount.count= this.credit.data && this.credit.data.min_sum? this.credit.data.min_sum :1000
        this.creditCount.month=this.credit&&this.credit.data&&this.credit.data.min_month ? this.credit.data.min_month :1
        this.credit_percent_year=this.credit&&this.credit.data&&this.credit.data.percent_year ? this.credit.data.percent_year :10
        this.credit_percent_month=this.credit&&this.credit.data&&this.credit.data.percent_month ? this.credit.data.percent_month :10
    }
}
</script>
